$(function() {

	var menuCurtain = $('.menu_curtain');
	var menuCurtainExists = menuCurtain.length > 0;

	if (menuCurtainExists) {

		var menuLinks = menuCurtain.find('.menu-links');
		var menuHeight = menuLinks.height();

		menuLinks.height(0);
		menuLinks.css('opacity', 1);

		menuCurtain.click(function() {
		
			if (menuCurtain.hasClass('menu_open')) {

				menuLinks.height(menuHeight);

			} else {

				menuLinks.height(0);

			}
		
		});

	}

});