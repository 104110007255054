$(function() {

	init();

	if ($.cookie('no_first') === 'true') {

		showLogo();
		showTitleMajor();
		showTitleMinor();
		showProducts();
		showMenu();
		showCopyright();

		$.cookie('no_first', null);

	} else {

		initTransition();

		setTimeout(function() {
		
			setTimeout(showLogo, 0);
			setTimeout(showTitleMajor, 1000);
			setTimeout(showTitleMinor, 1100);
			setTimeout(showProducts, 1100);
			setTimeout(showMenu, 1400);
			setTimeout(showCopyright, 1400);
		
		}, 500);

	}

	function showLogo() {

		var logo = $('.section-1__logo');
		logo.addClass('section-1__logo_show');

	}

	function showTitleMajor() {

		var titleMain = $('.section-1__title-major');
		titleMain.addClass('section-1__title-major_show');

	}

	function showTitleMinor() {

		var titleMinor = $('.section-1__title-minor');
		titleMinor.addClass('section-1__title-minor_show');

	}

	function showMenu() {
	
		var menu = $('.section-menu');
		menu.addClass('section-menu_show');

	}

	function showCopyright() {
	
		var copyright = $('.section-copyright');
		copyright.addClass('section-copyright_show');

	}

	function showProducts() {
	
		var products = $('.section-1__product');
		products.addClass('section-1__product_show');
	
	}

	function init() {

		var cap = $('.section-cap');
		cap.fadeOut();

	}

	function initTransition() {

		var logo = $('.section-1__logo');
		logo.addClass('section-1__logo_transition');

		var titleMain = $('.section-1__title-major');
		titleMain.addClass('section-1__title-major_transition');

		var titleMinor = $('.section-1__title-minor');
		titleMinor.addClass('section-1__title-minor_transition');

		var menu = $('.section-menu');
		menu.addClass('section-menu_transition');

		var copyright = $('.section-copyright');
		copyright.addClass('section-copyright_transition');

		var products = $('.section-1__product');
		products.addClass('section-1__product_transition');

		// $.cookie('no_first', 'true');

	}

});