$(function() {

	var menuButton = $('.menu .menu-button');

	menuButton.click(function(e) {
		// e.preventDefault();
	
		menuButton.toggleClass('menu-button_active');

		var menu = menuButton.parents('.menu');
		menu.toggleClass('menu_open');

	
	});

});