$(function() {

	var curtains = {
		buffer: {
			element: $('.section-curtain_buffer'),
			open: false,
		},
		ballon: {
			element: $('.section-curtain_ballon'),
			open: false,
		},
	};

	var products = {
		buffer: {
			element: $('.section-1__product_buffer'),
		},
		ballon: {
			element: $('.section-1__product_ballon'),
		},
	};

	$('.section-1__product').click(function() {

		var sectionCurtain = $('.section-curtain');
		sectionCurtain.addClass('section-curtain_transition');

		var productName = $(this).attr('product');

		switch(products[productName]) {
			case products.buffer:
				if (!curtains.buffer.open) {
					if (!curtains.ballon.open) {
						showProduct(products.buffer);
					} else {
						hideProducts();
					}
				}
				break;

			case products.ballon:
				if (!curtains.ballon.open) {
					if (!curtains.buffer.open) {
						showProduct(products.ballon);
					} else {
						hideProducts();
					}
				}
				break;
		}

	});

	$('.curtain-content__button-close').click(function() {
	
		hideProducts();
	
	});

	$('a').click(function(e) {
		e.preventDefault();

		var href = $(this).attr('href');

		if (href != undefined) {

			var cap = $('.section-cap');
			cap.fadeIn(function() {
			
				window.location.href = href;
			
			});

		}
	
	});

	$(document).keyup(function(e) {
		if (e.keyCode === 27) { // esc
			hideProducts();
		}
	});

	function showProduct(product) {

		switch(product) {
			case products.buffer:
				curtains.buffer.open = true;
				curtains.buffer.element.addClass('section-curtain_active');
				products.buffer.element.addClass('section-1__product_to-top');
				products.ballon.element.addClass('section-1__product_to-bottom');
				break;

			case products.ballon:
				curtains.ballon.open = true;
				curtains.ballon.element.addClass('section-curtain_active');
				products.ballon.element.addClass('section-1__product_to-top');
				products.buffer.element.addClass('section-1__product_to-bottom');
				break;

			default:
				return;
		}

		$('.menu-links').addClass('menu-links_to-top');
		$('.logo_menu').addClass('logo_menu_to-top');

		toggleCurtainContentElements(true);

	}

	function hideProducts() {

		curtains.buffer.element.removeClass('section-curtain_active');
		curtains.ballon.element.removeClass('section-curtain_active');

		setTimeout(function() {

			products.buffer.element.removeClass('section-1__product_to-top');
			products.ballon.element.removeClass('section-1__product_to-bottom');
			products.ballon.element.removeClass('section-1__product_to-top');
			products.buffer.element.removeClass('section-1__product_to-bottom');

			curtains.ballon.open = false;
			curtains.buffer.open = false;

		}, 600);

		$('.menu-links').removeClass('menu-links_to-top');
		$('.logo_menu').removeClass('logo_menu_to-top');

		toggleCurtainContentElements(false);

	}

	function toggleCurtainContentElements(state) {
	
		var title = $('.curtain-content__title');
		var description = $('.curtain-content__description');
		var advantages = $('.curtain-content__advantages');
		var button = $('.curtain-content__button');
		var buttonClose = $('.button-close');
		
		title.toggleClass('curtain-content__title_show', state);
		description.toggleClass('curtain-content__description_show', state);
		advantages.toggleClass('curtain-content__advantages_show', state);
		button.toggleClass('curtain-content__button_show', state);
		buttonClose.toggleClass('button-close_show', state);

	}

	function init() {
	
		var coefficient = 25;
		var windowHeight = $(window).height();

		var triangle = $('<div>');
		triangle.addClass('section-curtain__triangle');
		triangle.css({
			'border-top-width': windowHeight,
		});

		var triangleBuffer = triangle.clone();
		triangleBuffer.css({
			'right': -(windowHeight/coefficient),
			'border-right-width': windowHeight/coefficient,
		});

		curtains.buffer.element.append(triangleBuffer);
		curtains.buffer.element.css({
			'left': -(windowHeight/coefficient),
		});

		var triangleBallon = triangle.clone();
		triangleBallon.css({
			'left': -(windowHeight/coefficient),
			'border-left-width': windowHeight/coefficient,
		});

		curtains.ballon.element.append(triangleBallon);
		curtains.ballon.element.css({
			'right': -(windowHeight/coefficient),
		});

	}

	init();

});